body {
  background: url(/assets/images/background.jpg) no-repeat #111;
  background-position: center top;
  margin: 0;

  @media (min-width: 1444px) {
    background-size: cover;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    background: url(/assets/images/mobile-background.jpg) no-repeat;
    background-position: center top;
    background-size: cover;
  }
}

body,
html {
  height: 100vh;
}

@font-face {
  font-family: 'chocolate';
  src: url(/assets/fonts/chocolate-covered-raindrops.ttf);
}
