.home {
  height: 100vh;

  button.home__button {
    color: #ff597a;
    background-color: #ffffff;
    margin-bottom: 5px;
    border: initial;
    font-family: 'chocolate';
    font-size: 25px;
    line-height: 25px;
  }
}
