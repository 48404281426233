@import '~react-image-gallery/styles/scss/image-gallery.scss';

.lessons {
  div.in-person-class__text-input {
    padding-right: 32px;
  }

  &__dialog-content {
    text-align: center;
  }

  &__dialog-text {
    font-family: 'chocolate';
    font-size: 45px;
    margin: 0;
  }
}
