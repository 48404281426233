.avatar {
  &__image {
    border: solid white 2px;
  }

  &__name {
    color: #ffb7c5;
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff,
      0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff,
      -1px 1px 0 #fff;
    font-family: 'chocolate';
    font-size: 50px;
    margin: 0;
    padding-right: 30px;
  }

  &__social-networks {
    margin-right: 20px;
  }

  &__container {
    text-align: center;
    text-align: -webkit-center;
  }
}
