.contact-form {
  div.contact-form__text-input {
    padding-right: 32px;
  }

  &__form-title {
    font-family: 'chocolate';
    font-size: 50px;
    margin: 0;
  }

  &__text-input {
    width: 40%;

    @media (min-width: 320px) and (max-width: 767px) {
      width: 100%;
    }
  }
}
