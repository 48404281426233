.about {
  &__self-introduction-title {
    font-family: 'chocolate';
    font-size: 50px;
  }

  &__self-introduction-text {
    font-family: 'chocolate';
    font-size: 28px;
  }
}
